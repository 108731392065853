<template>
    <div v-if="action" class="template-action">
        <!-- TYPE EMAIL -->
        <div class="email" v-if="action.type && action.type == 'email'">
            <div class="icon"></div>
            <div class="content">
                <div class="info">
                    <div class="row">
                        <span>{{ $t('template.general.send_to') }}: </span>

                        <!-- <input class="integrated-input text to" v-model="action.to" :placeholder="$t('template.general.notification_email_placeholder')" :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_to') }" /> -->
                        <v-combobox
                            dense
                            v-model="toEmails"
                            class="combobox translate-disable"
                            :placeholder="$t('template.general.notification_email_placeholder')"
                            hide-selected
                            multiple
                            hide-details
                            small-chips
                            solo
                            flat
                            deletable-chips
                            attach
                            append-icon=""
                            disable-lookup
                            @change="formatEmails()"
                            :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_to') }"
                        >
                        </v-combobox>
                    </div>
                    <div class="row">
                        <span>{{ $t('template.general.subject') }}: </span>
                        <TranslateInput
                            :translation_keyname="'tpl.' + context.template_id + '.item.' + context.item_id + '.alerts.' + action_index + '.subject'"
                            v-model="action.subject"
                            @change="action = action"
                            @click="action = action"
                            :_placeholder="$t('template.general.notification_subject_placeholder')"
                            :_class="[
                                'integrated-input',
                                'text',
                                'subject',
                                { error: action.errors && action.errors.codes && action.errors.codes.includes('empty_subject') }
                            ]"
                        ></TranslateInput>
                        <!-- <input class="integrated-input text subject" v-model="action.subject" @change="action = action" @click="action = action" :placeholder="$t('template.general.notification_subject_placeholder')" :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_subject') }" /> -->
                    </div>
                </div>
                <TranslateInput
                    :translation_keyname="'tpl.' + context.template_id + '.item.' + context.item_id + '.alerts.' + action_index + '.msg'"
                    _type="textarea"
                    v-model="action.message"
                    :_placeholder="$t('template.general.notification_message_placeholder')"
                    @click="action = action"
                    @change="action = action"
                    :_class="[
                        'integrated-input',
                        'text',
                        'fullwidth',
                        'message',
                        { error: action.errors && action.errors.codes && action.errors.codes.includes('empty_msg') }
                    ]"
                    rows="1"
                ></TranslateInput>
                <!-- <textarea class="integrated-input text fullwidth message" v-model="action.message" @change="action = action" @click="action = action" :placeholder="$t('template.general.notification_message_placeholder')" :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_message') }" /> -->
            </div>
        </div>

        <!-- NORMAL ALERT AND ISSUE ALERT -->
        <div class="alert" :data-type="action.class ? action.class : 'info'" v-else>
            <div class="icon" :data-type="action.class ? action.class : 'info'"></div>
            <div class="content">
                <div class="info">
                    <div class="left" :class="{ fullwidth: !action.tpl }">
                        {{ $t('template.general.alert_of_type') }}:

                        <select :value="action.class" class="integrated-input translate-disable" v-model.lazy="action.class" @change="action = action">
                            <option
                                v-for="(alert, alert_index) in [
                                    { text: $t('template.general.alert_class.info'), value: 'info' },
                                    { text: $t('template.general.alert_class.alert'), value: 'alert' },
                                    { text: $t('template.general.alert_class.error'), value: 'error' }
                                ]"
                                :value="alert.value"
                                :key="alert_index"
                            >
                                {{ alert.text }}
                            </option>
                        </select>
                    </div>
                    <div
                        v-if="typeof action.tpl != 'undefined'"
                        class="right"
                        :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_tpl') }"
                    >
                        {{ suggestText }}:

                        <select
                            :value="action.tpl"
                            class="integrated-input translate-disable"
                            v-model.lazy="action.tpl"
                            @change="action = action"
                            :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_tpl') }"
                        >
                            <option v-for="(issue, issue_index) in Object.values(issuesTemplates)" :key="issue_index" :value="issue.id">
                                {{ issue.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <TranslateInput
                    :translation_keyname="'tpl.' + context.template_id + '.item.' + context.item_id + '.alerts.' + action_index + '.msg'"
                    _type="textarea"
                    v-model="action.msg"
                    :_placeholder="$t('template.general.alert_message_placeholder')"
                    @click="action = action"
                    @change="action = action"
                    :_class="[
                        'integrated-input',
                        'text',
                        'fullwidth',
                        'message',
                        { error: action.errors && action.errors.codes && action.errors.codes.includes('empty_msg') }
                    ]"
                    rows="1"
                ></TranslateInput>
                <!-- <textarea class="integrated-input text fullwidth message" v-model="action.msg" @change="action = action" @click="action = action" :placeholder="$t('template.general.alert_message_placeholder')" :class="{ error: action.errors && action.errors.codes && action.errors.codes.includes('empty_msg') }" /> -->
            </div>
        </div>
        <!-- v-show="falseitem.id == itemSelected.id" -->
        <div v-show="context.item_id == selectedContext.item_id" @click="deleteAlert(context.action_id)" class="delete translate-hide" data-type="delete"></div>
    </div>
</template>

<script>
export default {
    name: 'Action',
    props: {
        context: { type: Object, default: {} },
        action_index: { type: [Number, Boolean], default: {} }
    },
    data() {
        return {
            toEmails: []
        }
    },
    computed: {
        action: {
            get() {
                var action = { ...this.$store.getters['template/getAlert'](this.context) }
                return action
            },
            set(value) {
                if (this.issuesTemplates && value.tpl) {
                    value.tplType = Object.values(this.issuesTemplates).find((item) => item.id == value.tpl).type
                }
                this.$store.commit('template/updateAlert', { context: this.context, alert: value })
            }
        },

        issuesTemplates() {
            let issuesTemplates = this.$store.getters['template/getIssueTemplates']

            issuesTemplates = issuesTemplates && Array.isArray(issuesTemplates) ? issuesTemplates : Object.values(issuesTemplates || {})

            var template = {
                ...this.$store.getters['template/getTemplate'](this.$route.params.id)
            }
            if (template.type == 2) {
                let templatesArray = Object.values(issuesTemplates)
                let actionPlanTemplates = templatesArray.filter((item) => item.type == 5)
                actionPlanTemplates.push(templatesArray.find((item) => item.id == this.action.tpl))
                return actionPlanTemplates.filter((item) => item != null)
            } else {
                return issuesTemplates.filter((item) => item != null)
            }
        },

        selectedContext() {
            // GET ACTIVE SELECTED ITEM
            return this.$store.getters['template/getSelectedContext']
        },
        suggestText() {
            if (this.$route.params.id) {
                var template = {
                    ...this.$store.getters['template/getTemplate'](this.$route.params.id)
                }
            } else {
                var template = { ...this.$store.getters['template/getTemplate'](-1) }
            }
            if (template.type == 2) {
                return this.$t('template.general.action_plan_suggested')
            } else {
                return this.$t('template.general.issue_suggested')
            }
        }
    },
    methods: {
        load() {
            if (this.action.type == 'email') {
                if (this.action.to) this.toEmails = [...this.action.to.split(',')]
            }
        },
        formatEmails() {
            var action = { ...this.action }
            action.to = this.toEmails.join(',')
            this.action = action
        },

        deleteAlert(alert_index) {
            var self = this
            this.$popup.confirm({
                message: this.$t('template.general.popup_delete_alert_msg'),
                textSave: this.$t('template.general.popup_delete_alert_confirm'),
                callSave: function () {
                    self.$store.commit('template/deleteAlert', { context: self.context, action_index: alert_index })
                },
                callCancel: function () {
                    this.$popup.close()
                }
            })
        }
    },
    created() {
        this.load()
    },
    watch: {
        action: {
            handler: function (val, oldVal) {
                this.load()
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.combobox {
    button.v-icon {
        background-color: transparent !important;
    }
}
</style>

<style lang="scss">
.template-action {
    padding-right: 50px;
    padding: 0 30px;
    position: relative;

    input.text {
        &.error {
            @include background($image: img('warning_error.svg'), $size: 15px, $position: right 14px center);
            // background-color: rgba($color-error-500, 0.03);
            padding-right: 30px;

            @include placeholder {
                color: $color-error-500;
            }
            &.to {
                @include background($image: img('warning_error.svg'), $size: 15px, $position: right 4px center);
            }
            &.subject {
                @include background($image: img('warning_error.svg'), $size: 15px, $position: right 0px center);
            }
        }
    }

    .email {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        background-color: #fff;
        border-radius: 6px;
        padding: 5px;
        overflow: hidden;
        margin: 15px 0;

        .icon {
            @include background($size: 20px, $image: img('format_mail_main_t20.svg'), $position: center left);
            margin: 0 9px;
            height: 24px;
            width: 24px;
            float: left;
        }
        .content {
            float: right;
            width: calc(100%);

            .info {
                overflow: hidden;
                width: 100%;
                margin-bottom: 3px;
                display: block;

                .row {
                    @include display-flex();
                    @include align-items();
                    width: 100%;
                    margin-left: 0;
                    min-height: 30px;

                    .combobox {
                        padding: 0;
                        width: 100%;
                        .v-icon--right {
                            margin-right: 10px;
                        }
                    }
                    .v-input__slot {
                        padding: 5px;
                    }

                    input {
                        @include font-size('sm');
                        font-family: $text;
                    }
                    .integrated-input {
                        padding-left: 12px;
                    }
                    // .message {
                    //     padding-top: 6px !important;
                    // }
                }

                span {
                    @include font-size('s');
                    font-family: $text-medium;
                    color: $color-neutral-600;
                    width: fit-content;
                    display: contents;
                }

                .integrated-input {
                    width: 100%;
                    padding-top: 0px;

                    &::placeholder {
                        font-family: $text;
                    }
                }

                .left {
                    @include font-size('s');
                    float: left;
                    font-family: $text;
                    color: $color-neutral-600;
                }
                .right {
                    @include font-size('s');
                    float: right;
                    font-family: $text;
                    color: $color-neutral-600;
                }
            }
        }
        .message {
            @include font-size('sm');
            float: left;
            width: 100%;
            color: $color-black;
            font-family: $text;
            // padding-top: 0px;

            &.error {
                color: $color-error-800;
            }
        }
    }

    .alert {
        @include display-flex();
        @include justify-content();
        @include align-items();
        background-color: $color-white;
        border-radius: 6px;
        padding: 5px;
        overflow: hidden;
        margin: 15px 0;
        border-left: 5px solid $color-warning-500;

        .icon {
            @include background($size: 14px, $image: img('alert_ffffff.svg'), $position: center top 4px);
            border-radius: 100%;
            margin: 0 8px 0 4px;
            height: 24px;
            width: 24px;
            float: left;

            &[data-type='info'] {
                background-color: $color-info-800;
            }
            &[data-type='alert'] {
                background-color: $color-warning-500;
            }
            &[data-type='error'] {
                background-color: $color-error-500;
            }
        }
        .content {
            float: right;
            width: calc(100% - 40px);

            .info {
                overflow: hidden;
                width: 100%;

                .left {
                    @include font-size('s');
                    float: left;
                    font-family: $text;
                    color: $color-neutral-600;
                    display: grid;
                }
                .right {
                    @include font-size('s');
                    text-align: right;
                    float: right;
                    font-family: $text;
                    color: $color-neutral-600;

                    // &.error {
                    //     @include background($image: img('warning_error.svg'), $size: 15px, $position: right 14px center);
                    //     // background-color: rgba($color-error-500, 0.03);
                    //     padding-right: 30px;
                    // }
                }

                .integrated-input {
                    height: 1.2rem;
                    display: inline-block;
                    width: fit-content;
                    min-width: 30px !important;
                    border-radius: 3px;
                    margin-right: 5px;

                    &.error {
                        border: 1px solid $color-error-500 !important;
                    }
                }
            }
        }
        .message {
            @include font-size('sm');
            float: left;
            width: 100%;
            color: $color-black;
            font-family: $text;
        }
    }

    .delete {
        width: 50px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: calc(50% - 10px) !important;
    }
}
</style>
