var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.action)?_c('div',{staticClass:"template-action"},[(_vm.action.type && _vm.action.type == 'email')?_c('div',{staticClass:"email"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"row"},[_c('span',[_vm._v(_vm._s(_vm.$t('template.general.send_to'))+": ")]),_c('v-combobox',{staticClass:"combobox translate-disable",class:{ error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_to') },attrs:{"dense":"","placeholder":_vm.$t('template.general.notification_email_placeholder'),"hide-selected":"","multiple":"","hide-details":"","small-chips":"","solo":"","flat":"","deletable-chips":"","attach":"","append-icon":"","disable-lookup":""},on:{"change":function($event){return _vm.formatEmails()}},model:{value:(_vm.toEmails),callback:function ($$v) {_vm.toEmails=$$v},expression:"toEmails"}})],1),_c('div',{staticClass:"row"},[_c('span',[_vm._v(_vm._s(_vm.$t('template.general.subject'))+": ")]),_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.context.template_id + '.item.' + _vm.context.item_id + '.alerts.' + _vm.action_index + '.subject',"_placeholder":_vm.$t('template.general.notification_subject_placeholder'),"_class":[
                            'integrated-input',
                            'text',
                            'subject',
                            { error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_subject') }
                        ]},on:{"change":function($event){_vm.action = _vm.action},"click":function($event){_vm.action = _vm.action}},model:{value:(_vm.action.subject),callback:function ($$v) {_vm.$set(_vm.action, "subject", $$v)},expression:"action.subject"}})],1)]),_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.context.template_id + '.item.' + _vm.context.item_id + '.alerts.' + _vm.action_index + '.msg',"_type":"textarea","_placeholder":_vm.$t('template.general.notification_message_placeholder'),"_class":[
                    'integrated-input',
                    'text',
                    'fullwidth',
                    'message',
                    { error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_msg') }
                ],"rows":"1"},on:{"click":function($event){_vm.action = _vm.action},"change":function($event){_vm.action = _vm.action}},model:{value:(_vm.action.message),callback:function ($$v) {_vm.$set(_vm.action, "message", $$v)},expression:"action.message"}})],1)]):_c('div',{staticClass:"alert",attrs:{"data-type":_vm.action.class ? _vm.action.class : 'info'}},[_c('div',{staticClass:"icon",attrs:{"data-type":_vm.action.class ? _vm.action.class : 'info'}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"left",class:{ fullwidth: !_vm.action.tpl }},[_vm._v("\n                    "+_vm._s(_vm.$t('template.general.alert_of_type'))+":\n\n                    "),_c('select',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.action.class),expression:"action.class",modifiers:{"lazy":true}}],staticClass:"integrated-input translate-disable",domProps:{"value":_vm.action.class},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.action, "class", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.action = _vm.action}]}},_vm._l(([
                                { text: _vm.$t('template.general.alert_class.info'), value: 'info' },
                                { text: _vm.$t('template.general.alert_class.alert'), value: 'alert' },
                                { text: _vm.$t('template.general.alert_class.error'), value: 'error' }
                            ]),function(alert,alert_index){return _c('option',{key:alert_index,domProps:{"value":alert.value}},[_vm._v("\n                            "+_vm._s(alert.text)+"\n                        ")])}),0)]),(typeof _vm.action.tpl != 'undefined')?_c('div',{staticClass:"right",class:{ error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_tpl') }},[_vm._v("\n                    "+_vm._s(_vm.suggestText)+":\n\n                    "),_c('select',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.action.tpl),expression:"action.tpl",modifiers:{"lazy":true}}],staticClass:"integrated-input translate-disable",class:{ error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_tpl') },domProps:{"value":_vm.action.tpl},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.action, "tpl", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.action = _vm.action}]}},_vm._l((Object.values(_vm.issuesTemplates)),function(issue,issue_index){return _c('option',{key:issue_index,domProps:{"value":issue.id}},[_vm._v("\n                            "+_vm._s(issue.name)+"\n                        ")])}),0)]):_vm._e()]),_c('TranslateInput',{attrs:{"translation_keyname":'tpl.' + _vm.context.template_id + '.item.' + _vm.context.item_id + '.alerts.' + _vm.action_index + '.msg',"_type":"textarea","_placeholder":_vm.$t('template.general.alert_message_placeholder'),"_class":[
                    'integrated-input',
                    'text',
                    'fullwidth',
                    'message',
                    { error: _vm.action.errors && _vm.action.errors.codes && _vm.action.errors.codes.includes('empty_msg') }
                ],"rows":"1"},on:{"click":function($event){_vm.action = _vm.action},"change":function($event){_vm.action = _vm.action}},model:{value:(_vm.action.msg),callback:function ($$v) {_vm.$set(_vm.action, "msg", $$v)},expression:"action.msg"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.context.item_id == _vm.selectedContext.item_id),expression:"context.item_id == selectedContext.item_id"}],staticClass:"delete translate-hide",attrs:{"data-type":"delete"},on:{"click":function($event){return _vm.deleteAlert(_vm.context.action_id)}}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }